<template>
    <div class="flex justify-between items-center w-full h-12 py-2 px-4 backdrop-blur">
        <!-- <ul class="menu circe flex justify-between items-center text-center text-sm h-12 gap-4">
            <li v-for="item in menu" :key="item" :class="{ activeMenu: s.activeMenu == item.url }">
                <a @click="$router.push(item.url), setActiveMemu(item.url)">{{ item.name }}</a>
            </li>
        </ul> -->
        <div @click="$router.push('/ads'), setActiveMemu('/ads')" class="font-bold text-sky-800 text-xl cursor-pointer">EridParser</div>

        <div class="flex items-center gap-4 h-12 font-medium text-sm text-slate-500" @click="$router.push('/profile'), setActiveMemu('/profile')">
            {{ profile?.name }}
            <a class="w-8 h-8 my-2 rounded-full overflow-hidden relative cursor-pointer">
                <img v-if="profile?.picture" class="w-full h-full object-cover" :src="profile?.picture" />
                <!-- :src="'https://eridapi.timekraken.ru/uploads/users/mini/' + profile?.picture" -->
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "TopMenu",

    data() {
        return {};
    },

    computed: {
        ...mapGetters(["s", "profile", "menu"]),
    },
    methods: {
        ...mapActions(["setActiveMemu"]),
    },
};
</script>
