<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(2.000000, 2.000000)" fill="currentColor" fill-rule="nonzero">
            <path
                d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 Z M13.6731175,6.5 C15.6957708,6.5 17.3354552,8.13968442 17.3354552,10.1623377 C17.3354552,12.1849909 15.6957708,13.8246753 13.6731175,13.8246753 L11.215,13.824 L11.215,15.227 L12.8518182,15.2272727 C13.3036875,15.2272727 13.67,15.5935852 13.67,16.0454545 C13.67,16.4973239 13.3036875,16.8636364 12.8518182,16.8636364 L11.215,16.863 L11.2154545,17.6428571 C11.2154545,18.1162441 10.8316986,18.5 10.3583117,18.5 L10.2180511,18.5 C9.74466419,18.5 9.36090826,18.1162441 9.36090826,17.6428571 L9.36,16.863 L8.48818182,16.8636364 C8.03631248,16.8636364 7.67,16.4973239 7.67,16.0454545 C7.67,15.5935852 8.03631248,15.2272727 8.48818182,15.2272727 L9.36,15.227 L9.36,13.824 L8.52714286,13.8246753 C8.05375593,13.8246753 7.67,13.4409194 7.67,12.9675325 L7.67,12.8116883 C7.67,12.3383014 8.05375593,11.9545455 8.52714286,11.9545455 L9.36,11.954 L9.36090826,7.35714286 C9.36090826,6.88375593 9.74466419,6.5 10.2180511,6.5 L13.6731175,6.5 Z M13.708961,8.21428571 L11.2154545,8.21428571 L11.2154545,11.9545455 L13.708961,11.9545455 C14.7418052,11.9545455 15.5790909,11.1172598 15.5790909,10.0844156 C15.5790909,9.05157138 14.7418052,8.21428571 13.708961,8.21428571 Z"
            ></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: "IVkMoney",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
