import { createStore } from "vuex";
import API from "../services/api";
import moment from "moment";

import account from "./modules/account";
import doc from "./modules/doc";
import profile from "./modules/profile";
import targetolog from "./modules/targetolog";
import vk from "./modules/vk";

export default createStore({
    modules: {
        account,
        doc,
        profile,
        targetolog,
        vk,
    },

    state: {
        alert: {},
        s: {
            findme: null,
            label: null,
            account_id: null,
            project_id: null,
            channel_id: null,
            campaign_id: null,
            client_id: null,
            targetolog_id: null,
            status: null,
            month: parseInt(moment().format("MM")),
            start: moment().format("YYYY-MM-01"),
            end: moment().endOf("month").format("YYYY-MM-DD"),
            view: null,
            popup: null,
            clear: null,
            order_name: null,
            order: true,
            request: false, // запрос отправлен = true / иначе показываем анимацию загрузки
            minirequest: false, // запрос отправлен = true / иначе показываем анимацию загрузки
            activeMenu: null,
            mode: null, // тема
        },
        select: {
            channels: [
                { id: null, name: "ВСЕ КАНАЛЫ" },
                { id: 1, name: "VK API" },
                { id: 2, name: "VK ADS" },
                { id: 3, name: "VK MARKET" },
            ],
            monthMin: ["", "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
            monthShort: { 1: "Янв", 2: "Фев", 3: "Мар", 4: "Апр", 5: "Май", 6: "Июн", 7: "Июл", 8: "Авг", 9: "Сен", 10: "Окт", 11: "Ноя", 12: "Дек" },
        },

        uploader: 0,
        select_month: ["", "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
        select_monthMin: ["", "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
        select_weekday: ["", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
        popup: false,
        progressBar: "",
        checkedIds: [],
    },

    mutations: {
        setAlert: (state, alert) => {
            state.alert = alert;
        },
        setSelect: (state, masters) => {
            state.select_masters = masters;
        },
        setSetting: (state, s) => {
            localStorage.setItem("s", JSON.stringify(s));
            state.s = s;
        },
        setUploader: (state, uploader) => {
            state.uploader = uploader;
        },
        setCheckedIds: (state, ids) => {
            state.checkedIds = ids;
        },
    },

    actions: {
        clearAlert(ctx) {
            ctx.commit("setAlert", null);
        },

        getSelects(ctx) {
            console.log("getSelects");
            API.GET("profile/selects").then((res) => {
                ctx.commit("setSelect", res.data.workers);
            });
        },

        request(ctx, status) {
            ctx.state.s.request = status;
            ctx.commit("setSetting", ctx.state.s);
        },

        minirequest(ctx, status) {
            ctx.state.s.minirequest = status;
            ctx.commit("setSetting", ctx.state.s);
        },

        loadSettings(ctx) {
            let s = JSON.parse(localStorage.getItem("s"));
            if (s) {
                s.minirequest = false;
                ctx.commit("setSetting", s);
            }
        },

        setSettings(ctx, s) {
            if (ctx.state.s.end < ctx.state.s.start) ctx.state.s.end = ctx.state.s.start;
            if (ctx.state.s.findme || ctx.state.s.label) ctx.state.s.clear = true;
            ctx.commit("setSetting", s);
        },

        clearSettings(ctx) {
            ctx.state.s.findme = null;
            ctx.state.s.label = null;
            ctx.state.s.project_id = null;
            ctx.state.s.channel_id = -1;
            ctx.state.s.campaign_id = null;
            ctx.state.s.client_id = null;
            ctx.state.s.targetolog_id = null;
            ctx.state.s.status = null;
            ctx.state.s.month = parseInt(moment().format("MM"));
            ctx.state.s.start = moment().format("YYYY-MM-01");
            ctx.state.s.end = moment().endOf("month").format("YYYY-MM-DD");
            ctx.state.s.clear = null;
            ctx.state.s.order_name = null;
            ctx.state.s.request = false; // запрос отправлен = true / иначе показываем анимацию загрузки
            ctx.state.s.minirequest = false; // зап

            ctx.dispatch("filterProjects");
            ctx.dispatch("filterClients");

            ctx.commit("setSetting", ctx.state.s);
        },

        setMonth(ctx, month) {
            let date = moment().format("YYYY-") + month + "-01";
            ctx.state.s.month = month;
            ctx.state.s.start = moment(date).format("YYYY-MM-01");
            ctx.state.s.end = moment(date).endOf("month").format("YYYY-MM-DD");
            ctx.commit("setSetting", ctx.state.s);
        },

        setPeriod(ctx, data) {
            ctx.state.s.month = null;
            ctx.state.s.start = data.start;
            ctx.state.s.end = data.end;
            ctx.commit("setSetting", ctx.state.s);
        },

        setTargetolog(ctx, user) {
            ctx.state.s.targetolog = user;
            ctx.commit("setSetting", ctx.state.s);
        },

        setPopup(ctx, popup) {
            ctx.state.s.popup = popup;
            ctx.commit("setSetting", ctx.state.s);
        },

        setMode(ctx, mode) {
            ctx.state.s.mode = mode;
            ctx.commit("setSetting", ctx.state.s);
        },

        setView(ctx, view) {
            ctx.state.s.view = view;
            ctx.commit("setSetting", ctx.state.s);
        },

        setActiveMemu(ctx, active) {
            ctx.state.s.activeMenu = active;
            ctx.commit("setSetting", ctx.state.s);
        },

        setOrder(ctx, name) {
            if (ctx.state.s.order_name == name) {
                ctx.state.s.order = !ctx.state.s.order;
            }
            ctx.state.s.order_name = name;
        },

        closePopup(ctx) {
            ctx.state.s.popup = false;
            ctx.commit("setSetting", ctx.state.s);
        },

        setUploader(ctx, status) {
            ctx.state.uploader = status;
            ctx.commit("setUploader", ctx.state.uploader);
        },

        checkboxId(ctx, id) {
            if (ctx.state.checkedIds.includes(id)) {
                ctx.state.checkedIds = ctx.state.checkedIds.filter((item) => item != id);
            } else {
                ctx.state.checkedIds.push(id);
            }
            ctx.commit("setCheckedIds", ctx.state.checkedIds);
        },

        filterList(ctx, list) {
            let newList = list;

            if (ctx.state.s.findme != "" && ctx.state.s.findme != undefined) {
                newList = newList.filter((item) => {
                    let id = null;
                    let name = null;
                    let project = null;
                    let inn = null;
                    let phone = null;

                    id = item.id.toString().indexOf(ctx.state.s.findme.toLowerCase()) !== -1;
                    if (item.name) name = item.name?.toLowerCase().indexOf(ctx.state.s.findme?.toLowerCase()) !== -1;
                    if (item.project) project = item.project?.toLowerCase().indexOf(ctx.state.s.findme?.toLowerCase()) !== -1;
                    if (item.inn) inn = item.inn?.toLowerCase().indexOf(ctx.state.s.findme?.toLowerCase()) !== -1;
                    if (item.phone) phone = item.phone?.toLowerCase().indexOf(ctx.state.s.findme?.toLowerCase()) !== -1;
                    if (id || name || project || inn || phone) return true;

                    return false;
                });
            }

            if (ctx.state.s.label != "" && ctx.state.s.label != undefined) {
                newList = newList.filter((item) => item.label?.toLowerCase().indexOf(ctx.state.s.label?.toLowerCase()) !== -1);
            }

            if (ctx.state.s.status != "" && ctx.state.s.status != undefined) {
                newList = newList.filter((item) => parseInt(item.status) == parseInt(ctx.state.s.status));
            }
            if (ctx.state.s.status === 0) {
                newList = newList.filter((item) => parseInt(item.status) == parseInt(ctx.state.s.status));
            }

            if (parseInt(ctx.state.s.project_id) > 0) {
                newList = newList.filter((item) => parseInt(item.project_id) == parseInt(ctx.state.s.project_id));
            }

            if (parseInt(ctx.state.s.channel_id) > 0) {
                newList = newList.filter((item) => parseInt(item.channel_id) == parseInt(ctx.state.s.channel_id));
            }

            if (parseInt(ctx.state.s.client_id) > 0) {
                newList = newList.filter((item) => parseInt(item.client_id) == parseInt(ctx.state.s.client_id));
            }

            ctx.dispatch("setSettings", ctx.state.s);
            return newList;
        },
    },

    getters: {
        alert(state) {
            return state.alert;
        },
        s(state) {
            return state.s;
        },
        uploader(state) {
            return state.uploader;
        },
        select(state) {
            return state.select;
        },
        selectMonth(state) {
            return state.select_month;
        },
        selectMonthMin(state) {
            return state.select_monthMin;
        },
        selectWeekDay(state) {
            return state.select_weekday;
        },
        popup(state) {
            return state.popup;
        },
        progressBar(state) {
            return state.progressBar;
        },
        checkedIds(state) {
            return state.checkedIds;
        },
    },
});
