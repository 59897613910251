import IVk from "@/components/icons/IVk";
import IVkAd from "@/components/icons/IVkAd";
import IVkAds from "@/components/icons/IVkAds";
import IVkCamp from "@/components/icons/IVkCamp";
import IVkMoney from "@/components/icons/IVkMoney";
import IVkUser from "@/components/icons/IVkUser";

import IcoBig from "@/components/IcoBig";
import IconAltDown from "@/components/icons/IconAltDown";
import IconAltUp from "@/components/icons/IconAltUp";
import IconBack from "@/components/icons/IconBack";
import IconCalendar from "@/components/icons/IconCalendar";
import IconClock from "@/components/icons/IconClock";
import IconPlus from "@/components/icons/IconPlus";
import IconEdit from "@/components/icons/IconEdit";
import IconDelete from "@/components/icons/IconDelete";
import IconDown from "@/components/icons/IconDown";
import IconClick from "@/components/icons/IconClick";
import IconClose from "@/components/icons/IconClose";
import IconClone from "@/components/icons/IconClone";
import IconGood from "@/components/icons/IconGood";
import IconBad from "@/components/icons/IconBad";
import IconLink from "@/components/icons/IconLink";
import IconList from "@/components/icons/IconList";
import IconLoader from "@/components/icons/IconLoader";
import IconRe from "@/components/icons/IconRe";
import IconRocket from "@/components/icons/IconRocket";
import IconTelegram from "@/components/icons/IconTelegram";
import IconHome from "@/components/icons/IconHome";
import IconMore from "@/components/icons/IconMore";
import IconMinus from "@/components/icons/IconMinus";
import IconOk from "@/components/icons/IconOk";
import IconSetting from "@/components/icons/IconSetting";
import IconStar from "@/components/icons/IconStar";
import IconStop from "@/components/icons/IconStop";
import IconProtfolio from "@/components/icons/IconProtfolio";
import IconEye from "@/components/icons/IconEye";
import IconEyeClose from "@/components/icons/IconEyeClose";
import IconUp from "@/components/icons/IconUp";
import IconWarning from "@/components/icons/IconWarning";
import IYa from "@/components/icons/IYa";
import ISenler from "@/components/icons/ISenler";

import Menu from "@/components/Menu";
import AddProject from "@/components/AddProject";
import ViewProject from "@/components/ViewProject";
import EditProject from "@/components/EditProject";
import DeleteProject from "@/components/DeleteProject";

import AddUser from "@/components/User/AddUser";
import EditUser from "@/components/User/EditUser";
import DeleteUser from "@/components/User/DeleteUser";

import LineGraf from "@/components/Graf/LineGraf";

import AddClient from "@/components/Targetolog/AddClient";

import EditAgent from "@/components/Ord/EditAgent";
import VerifyAgent from "@/components/Ord/VerifyAgent";
import CancelAgent from "@/components/Ord/CancelAgent";
import SendAgent from "@/components/Ord/SendAgent";
import EditContract from "@/components/Ord/EditContract";
import VerifyContract from "@/components/Ord/VerifyContract";
import CancelContract from "@/components/Ord/CancelContract";
import SendContract from "@/components/Ord/SendContract";

export default [
    IVk,
    IVkAd,
    IVkAds,
    IVkCamp,
    IVkMoney,
    IVkUser,

    IcoBig,
    IconAltDown,
    IconAltUp,
    IconBack,
    IconCalendar,
    IconClock,
    IconGood,
    IconBad,
    IconPlus,
    IconEdit,
    IconDelete,
    IconDown,
    IconClick,
    IconClose,
    IconClone,
    IconLink,
    IconList,
    IconLoader,
    IconMore,
    IconMinus,
    IconTelegram,
    IconHome,
    IconOk,
    IconSetting,
    IconStar,
    IconStop,
    IconProtfolio,
    IconRe,
    IconRocket,
    IconEye,
    IconEyeClose,
    IconUp,
    IconWarning,
    IYa,
    ISenler,

    Menu,
    AddProject,
    ViewProject,
    EditProject,
    DeleteProject,

    AddUser,
    EditUser,
    DeleteUser,

    EditAgent,
    VerifyAgent,
    CancelAgent,
    SendAgent,
    EditContract,
    VerifyContract,
    CancelContract,
    SendContract,

    LineGraf,

    AddClient,
];
