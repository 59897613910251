import API from "../../services/api";

export default {
    state: {
        accounts: [],
        accountsF: [],
        account: {},

        logs: [],
        logsF: [],

        users: [],
        usersF: [],
        user: {},

        roles: {
            Or: "Собственник",
            Bs: "Старший бюджетного отдела",
            Bo: "Бюджетный отдел",
            Ls: "Старший юрист",
            Lr: "Юрист",
            Tg: "Таргетолог",
        },
    },

    mutations: {
        setAccounts(state, accounts) {
            state.accounts = accounts;
            state.accountsF = accounts;
        },
        setAccountsF(state, accounts) {
            state.accountsF = accounts;
        },
        setAccount(state, account) {
            localStorage.setItem("account", JSON.stringify(account));
            state.account = account;
        },

        setLogs(state, logs) {
            state.logs = logs;
            state.logsF = logs;
        },
        setUsers(state, users) {
            state.users = users;
            state.usersF = users;
        },
        setUser(state, user) {
            state.user = user;
        },
    },

    actions: {
        getAccounts(ctx) {
            ctx.dispatch("loadSettings");

            API.GET("api/vk/account/list").then((res) => {
                ctx.commit("setAccounts", res.data.accounts);
            });
        },

        getUsers(ctx) {
            API.GET("user/list").then((res) => {
                ctx.commit("setUsers", res.data.users);
            });
        },

        pickUser(ctx, user) {
            ctx.commit("setUser", user);
        },

        createUser(ctx, data) {
            if (!data) return null;

            let formData = new FormData();
            formData.append("name", data.name);
            formData.append("secondname", data.secondname);
            formData.append("username", data.username);
            formData.append("email", data.email);
            formData.append("role", data.role);

            API.POST("account/create-user", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setUsers", res.data.users);
            });
        },

        saveUser(ctx, data) {
            if (!data) return null;

            let formData = new FormData();
            formData.append("roles", data.roles);
            formData.append("role", data.role);
            formData.append("name", data.name);
            formData.append("secondname", data.secondname);
            formData.append("username", data.username);
            formData.append("email", data.email);
            formData.append("tl", data.tl);

            API.POST("user/save-user", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setUsers", res.data.users);
                ctx.commit("setUser", res.data.user);
            });
        },

        addUserRole(ctx, role) {
            if (role == "Or" && ctx.rootState.profile.profile.role != "Or") return;

            console.log("addUserRole", role);
            let roles = JSON.parse(ctx.state.user.roles);
            roles.push(role);
            ctx.state.user.roles = JSON.stringify(roles);
            ctx.commit("setUser", ctx.state.user);
        },

        delUserRole(ctx, role) {
            console.log("delUserRole", role);
            let roles = JSON.parse(ctx.state.user.roles);
            ctx.state.user.roles = JSON.stringify(roles.filter((r) => r != role));
            ctx.commit("setUser", ctx.state.user);
        },
    },

    getters: {
        accounts(state) {
            return state.accountsF;
        },
        account(state) {
            return state.account;
        },
        logs(state) {
            return state.logsF;
        },
        users(state) {
            return state.usersF;
        },
        user(state) {
            return state.user;
        },
        roles(state) {
            return state.roles;
        },
    },
};
