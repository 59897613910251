<template>
    <div class="w-2/3 min-h-min h-4/5 fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto rounded z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-sky-600 text-white">ДОБАВИТЬ КЛИЕНТА</h3>

        <div class="flex flex-col p-8 pb-12 text-center overflow-auto">
            <div class="flex flex-col items-center mb-4">
                <label class="w-full text-sm text-left text-slate-400 mb-1">Напишите название проекта как в CRM</label>
                <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="find.project" />
            </div>

            <div class="flex flex-col items-center mb-4">
                <label class="w-full text-sm text-left text-slate-400 mb-1">Напишите название оффера как в CRM</label>
                <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="find.offer" />
            </div>

            <div class="flex flex-col items-center mb-4">
                <label class="w-full text-sm text-left text-slate-400 mb-1">Укажите номер клиента</label>
                <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="find.client_id" />
            </div>
        </div>

        <div class="flex justify-end gap-8 px-8 py-4 bg-white w-full absolute z-50 bottom-0">
            <div class="btn btn-green text-sm py-2 px-4" @click="linkMyClient(find), closePopup()">ДОБАВИТЬ</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "AddClient",

    data() {
        return {
            moment: moment,
            find: {},
        };
    },

    computed: {
        ...mapGetters(["s"]),
    },

    methods: {
        ...mapActions(["linkMyClient", "closePopup"]),
    },
};
</script>
