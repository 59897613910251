<template>
    <div class="w-1/2 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-200px)] rounded overflow-hidden z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-sky-600 text-white">РЕДАКТИРОВАТЬ КОНТРАКТ : {{ contract.serial }}</h3>

        <div class="flex flex-col p-8 pb-32 text-center h-full overflow-auto">
            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Номер договора</label>
                <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="contract.serial" />
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Тип договора</label>

                <div class="w-full flex justify-between items-center mb-4 text-sm bg-slate-100 text-slate-400 rounded">
                    <div
                        @click="contract.type = 'mediation'"
                        class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                        :class="{ '!bg-sky-600 !text-white': contract.type == 'mediation' }"
                    >
                        <span class="flex items-center">Посредничество</span>
                    </div>
                    <div
                        @click="contract.type = 'service'"
                        class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                        :class="{ '!bg-sky-600 !text-white': contract.type == 'service' }"
                    >
                        <span class="flex items-center">Оказание услуг</span>
                    </div>
                    <div
                        @click="contract.type = 'additional'"
                        class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                        :class="{ '!bg-sky-600 !text-white': contract.type == 'additional' }"
                    >
                        <span class="flex items-center">Доп соглашение</span>
                    </div>
                </div>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Заказчик</label>
                <div v-if="contract.agent_name" class="flex items-center gap-4">
                    {{ contract.agent_name }}
                    <div class="text-slate-400 hover:text-red-500 cursor-pointer" @click="contract.agent_name = ''"><IconClose :size="4" /></div>
                </div>

                <template v-else>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="find" />
                    <div
                        v-for="ag in findAgents"
                        :key="ag"
                        @click="(contract.agent_name = ag.name), (contract.agent_id = ag.external_id)"
                        class="text-left p-2 hover:bg-slate-50"
                    >
                        <div class="text-sm">{{ ag.name }}</div>
                        <div class="text-xs text-slate-400">{{ ag.inn }} {{ ag.inn }}</div>
                    </div>
                </template>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Исполнитель</label>
                <div v-if="contract.contractor_name" class="flex items-center gap-4">
                    {{ contract.contractor_name }}
                    <div class="text-slate-400 hover:text-red-500 cursor-pointer" @click="contract.contractor_name = ''"><IconClose :size="4" /></div>
                </div>

                <template v-else>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="find" />
                    <div
                        v-for="ag in findAgents"
                        :key="ag"
                        @click="(contract.contractor_name = ag.name), (contract.contractor_id = ag.external_id)"
                        class="text-left p-2 hover:bg-slate-50"
                    >
                        <div class="text-sm">{{ ag.name }}</div>
                        <div class="text-xs text-slate-400">{{ ag.inn }} {{ ag.inn }}</div>
                    </div>
                </template>
            </div>

            <!-- <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Исполнитель</label>
                <div v-if="contract.contractor_name" class="flex items-center gap-4">
                    {{ contract.contractor_name }}
                    <div class="text-slate-400 hover:text-red-500 cursor-pointer" @click="delContractContractor(contract)">
                        <IconClose :size="4" />
                    </div>
                </div>
                <input v-else type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="contract.contractor_name" />
            </div> -->

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Предмет договора</label>
                <select class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="contract.subject">
                    <option value="representation">представительство</option>
                    <option value="mediation">посредничество</option>
                    <option value="org_distribution">организацию распространения рекламы</option>
                    <option value="distribution">на распространение рекламы</option>
                    <option value="other">иное</option>
                </select>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Осуществляемые действия</label>
                <select class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="contract.action">
                    <option value=""></option>
                    <option value="distribution">в целях распространения рекламы</option>
                    <option value="conclude">заключение договоров</option>
                    <option value="commercial">коммерческое представительство</option>
                    <option value="other">иное</option>
                </select>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Дата договора</label>
                <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="contract.date" />
            </div>

            <hr />
            <p class="text-left text-sm text-slate-400 my-2">Дополнительно</p>

            <div class="flex text-sm mb-4">
                <label class="w-32 text-left">Статус</label>
                <div v-if="contract.status == 1" class="text-slate-400">Создан</div>
                <div v-if="contract.status == 2" class="text-sky-400">Проверен</div>
                <div v-if="contract.status == 3" class="text-red-400">Отклонен</div>
                <div v-if="contract.status == 7" class="text-teal-400">Отправлен в ОРД</div>
            </div>

            <div class="flex text-sm mb-4">
                <label class="w-32 text-left">Внешний ID</label>
                <div class="text-slate-400">{{ contract.external_id }}</div>
            </div>
        </div>

        <div class="flex justify-end gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0">
            <div class="btn btn-green text-sm py-2 px-4" @click="saveContract(contract), closePopup(), pickContract(null)">Сохранить</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "EditContract",

    data() {
        return {
            moment: moment,
            find: "",
        };
    },

    computed: {
        ...mapGetters(["s", "contract", "agents"]),

        findAgents() {
            if (!this.agents || this.agents.length == 0) return [];

            let data = this.agents.filter((item) => {
                let id = null;
                let name = null;
                let inn = null;
                let phone = null;

                id = item.external_id.toString().indexOf(this.find.toLowerCase()) !== -1;
                if (item.name) name = item.name?.toLowerCase().indexOf(this.find?.toLowerCase()) !== -1;
                if (item.inn) inn = item.inn?.toLowerCase().indexOf(this.find?.toLowerCase()) !== -1;
                if (item.phone) phone = item.phone?.toLowerCase().indexOf(this.find?.toLowerCase()) !== -1;
                if (id || name || inn || phone) return true;

                return false;
            });
            return data.slice(0, 5);
        },
    },

    methods: {
        ...mapActions(["saveContract", "closePopup", "pickContract"]),
    },

    mounted() {
        this.contract.status = 1;
    },
};
</script>
