<template>
    <div v-if="profile?.id" class="container rounded mx-auto lg:flex gap-4 pt-16">
        <div class="w-full p-4 bg-white">
            <div class="flex flex-col justify-center items-center text-base text-zinc-800 font-latobold">
                <div class="w-48 h-48 my-5 mx-auto rounded-full bg-black overflow-hidden relative">
                    <span class="w-full h-full group">
                        <input
                            type="file"
                            id="avatar"
                            class="absolute invisible opacity-0"
                            multiple
                            @change="uploadImg({ e: $event, type: 'profile', id: profile.user_id })"
                        />
                        <!-- <label
                            for="avatar"
                            class="w-1/2 h-1/2 flex items-center justify-center rounded-full absolute -top-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 group-hover:bg-black group-hover:opacity-50 group-hover:top-1/2"
                        >
                            <svg class="fill-white w-5 h-5">
                                <use xlink:href="@/assets/i.svg#ico-edit"></use>
                            </svg>
                        </label> -->
                        <img v-if="profile?.picture" class="w-full h-full object-cover" :src="profile?.picture" />
                        <img v-else class="w-full h-full object-cover" src="https://api.timekraken.ru/uploads/users/u0.png" />
                    </span>
                </div>

                {{ profile?.name }} {{ profile?.secondname }}
            </div>

            <div class="flex">
                <!-- <a class="mx-auto mt-4 v-btn bg-teal-500 hover:bg-teal-400 transition-colors duration-150" @click="editProfile">
                        <svg class="fill-white w-16px h-16px mr-3">
                            <use xlink:href="@/assets/i.svg#ico-edit"></use>
                        </svg>
                        <span class="v-btn_text">РЕДАКТИРОВАТЬ</span>
                    </a> -->
                <a
                    class="mx-auto mt-4 v-btn py-2 px-4 cursor-pointer rounded bg-rose-500 hover:bg-rose-400 transition-colors duration-150"
                    @click="logout()"
                >
                    <span class="text-white">ВЫХОД</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "ProfilePage",

    computed: {
        ...mapGetters(["s", "profile"]),
    },

    methods: {
        ...mapActions(["getProfile", "logout", "uploadImg", "editProfile"]),
    },

    mounted() {
        this.getProfile();
    },
};
</script>
