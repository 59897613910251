<template>
    <div class="h-screen min-h-screen flex justify-center items-center bg-sky-800">
        <div
            v-if="count > 0"
            class="fixed flex flex-col z-10 w-fit rounded-r-full rounded-tl-full bg-white/50 pb-2 px-8 mb-72 text-center text-sky-800 backdrop-blur shadow-2xl"
        >
            <span class="text-[36px] font-bold h-12">{{ count }}</span>
            <span class="text-sm">ПОЙМАНО</span>
        </div>

        <div
            @click="loginWithVk()"
            class="fixed flex flex-col z-20 w-96 rounded-r-full rounded-tl-full bg-white/50 py-5 px-10 text-center text-sky-800 backdrop-blur shadow-2xl hover:bg-white/80 hover:-mt-4 duration-1000 cursor-pointer"
        >
            <span class="text-[100px] font-bold h-32">ERID</span>
            <span class="text-sm">ПРОВЕРЬ ТОКЕНЫ</span>
        </div>

        <ul class="circles">
            <li v-for="li in list" :key="li" @click="popIt()"></li>
        </ul>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "LoginPage",

    data() {
        return {
            form: "login",
            name: null,
            email: null,
            username: null,
            password: null,
            count: 0,
            list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
        };
    },

    // watch: {
    //     timerCount: {
    //         handler(value) {
    //             if (value > 0) {
    //                 setTimeout(() => {
    //                     this.timerCount--;
    //                 }, 1000);
    //             }
    //         },
    //         immediate: true, // This ensures the watcher is triggered upon creation
    //     },
    // },

    methods: {
        ...mapActions(["login", "reg"]),

        loginWithVk() {
            const appId = 51545759;
            const redirectUri = "https://erid.timekraken.ru/auth";
            const vkAuthUrl = `https://oauth.vk.com/authorize?client_id=${appId}&display=page&redirect_uri=${redirectUri}&scope=email,ads,groups,offline&response_type=token&v=5.131`;
            window.location.href = vkAuthUrl;
        },
        popIt() {
            this.count++;
            event.currentTarget.classList.add("!hidden");
        },
    },
};
</script>
