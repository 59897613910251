<template>
    <div class="w-5/6 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-112px)] rounded overflow-hidden z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-cyan-500 text-white">РЕДАКТИРОВАТЬ ПРОЕКТ : {{ project.name }}</h3>

        <div class="flex flex-col p-8 pb-32 text-center h-full overflow-auto">
            <div class="grid grid-cols-3 border-b border-slate-200 pb-4 mb-8 gap-8">
                <div class="flex flex-col">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Название</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.name" />
                </div>

                <div class="grid grid-cols-2 gap-4">
                    <div class="flex flex-col">
                        <label class="w-full text-xs text-left text-slate-400 mb-1">FAQid</label>
                        <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.faqid" />
                    </div>
                    <div class="flex flex-col">
                        <label class="w-full text-xs text-left text-slate-400 mb-1">Отметка времени</label>
                        <input type="datetime-local" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.date" />
                    </div>
                </div>

                <div class="flex flex-col">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Организационно-правовая форма</label>

                    <div class="w-full flex justify-between items-center mb-4 text-sm bg-slate-100 text-slate-400 rounded">
                        <div
                            @click="project.client_type = 'person'"
                            class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                            :class="{ '!bg-teal-400 !text-white': project.client_type == 'person' }"
                        >
                            <span class="flex items-center">Физ. лицо</span>
                        </div>
                        <div
                            @click="project.client_type = 'individual'"
                            class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                            :class="{ '!bg-teal-400 !text-white': project.client_type == 'individual' }"
                        >
                            <span class="flex items-center">ИП</span>
                        </div>
                        <div
                            @click="project.client_type = 'legal'"
                            class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                            :class="{ '!bg-teal-400 !text-white': project.client_type == 'legal' }"
                        >
                            <span class="flex items-center">Юр. лицо</span>
                        </div>
                    </div>

                    <!-- <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.client_type" /> -->
                </div>
            </div>

            <div class="grid grid-cols-3 border-b border-slate-200 pb-8 mb-4 gap-4">
                <div class="flex flex-col items-center col-span-2">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Полное юридическое наименование</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.client_name" />
                </div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Сокращенное наименование</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.client_short" />
                </div>

                <div class="flex flex-col items-center col-span-2">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Адрес</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.address" />
                </div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Email</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.email" />
                </div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">ИНН</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.inn" />
                </div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">ОГРН/ОГРНИП</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.ogrn" />
                </div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Телефон</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.phone" />
                </div>
            </div>

            <div class="grid grid-cols-4 border-b border-slate-200 pb-8 mb-4 gap-4">
                <div class="col-span-4 text-cyan-600 text-left">Контактное лицо</div>
                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Контактное лицо</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.contact_name" />
                </div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Телефон контактного лица</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.contact_phone" />
                </div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Email контактного лица</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.contact_email" />
                </div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Telegram контактного лица</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.contact_tl" />
                </div>
            </div>

            <div class="grid grid-cols-4 border-b border-slate-200 pb-8 mb-4 gap-4">
                <div class="col-span-4 text-cyan-600 text-left">Субагент</div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Название субагента</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.subagent_name" />
                </div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Тип субагента</label>

                    <div class="w-full flex justify-between items-center text-sm bg-slate-100 text-slate-400 rounded">
                        <div
                            @click="project.subagent_type = 'person'"
                            class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                            :class="{ '!bg-teal-400 !text-white': project.subagent_type == 'person' }"
                        >
                            <span class="flex items-center">Физ. лицо</span>
                        </div>
                        <div
                            @click="project.subagent_type = 'individual'"
                            class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                            :class="{ '!bg-teal-400 !text-white': project.subagent_type == 'individual' }"
                        >
                            <span class="flex items-center">ИП</span>
                        </div>
                        <div
                            @click="project.subagent_type = 'legal'"
                            class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                            :class="{ '!bg-teal-400 !text-white': project.subagent_type == 'legal' }"
                        >
                            <span class="flex items-center">Юр. лицо</span>
                        </div>
                    </div>

                    <!-- <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.subagent_type" /> -->
                </div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Инн субагента</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.subagent_inn" />
                </div>

                <div class="flex flex-col items-center">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Телефон субагента</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.subagent_phone" />
                </div>
            </div>

            <div class="grid grid-cols-4 border-b border-slate-200 pb-8 mb-4 gap-4">
                <div class="col-span-4 text-cyan-600 text-left">Контракт</div>

                <div class="flex flex-col items-center mb-4">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Номер контракта</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.contract_number" />
                </div>

                <div class="flex flex-col items-center mb-4">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Дата контракта</label>
                    <input type="date" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.contract_date" />
                </div>

                <div class="flex flex-col items-center mb-4">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">ОКВЭД</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.okved" />
                </div>

                <div class="flex flex-col mb-4">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">НДС</label>

                    <div class="w-full flex justify-between items-center text-sm bg-slate-100 text-slate-400 rounded">
                        <div
                            @click="project.with_vat = 3"
                            class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                            :class="{ '!bg-teal-400 !text-white': project.with_vat == 3 }"
                        >
                            <span class="flex items-center">С НДС</span>
                        </div>

                        <div
                            @click="project.with_vat = 2"
                            class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                            :class="{ '!bg-teal-400 !text-white': project.with_vat == 2 }"
                        >
                            <span class="flex items-center">Без НДС</span>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col items-center col-span-2">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Тип контракта</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.contract_type" />
                </div>

                <div class="flex flex-col items-center col-span-2">
                    <label class="w-full text-xs text-left text-slate-400 mb-1">Предмет договора</label>
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.contract_object" />
                </div>
            </div>
        </div>

        <div class="flex justify-end gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0">
            <!-- <div class="btn btn-empty-red text-sm py-2 px-4" @click="popupDelProject(project)">Удалить</div> -->

            <div class="btn btn-green text-sm py-2 px-4" @click="saveProject(project), closePopup()">Сохранить</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "EditProject",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "project"]),
    },

    methods: {
        ...mapActions(["saveProject", "popupDelProject", "closePopup"]),
    },
};
</script>
