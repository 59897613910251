<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
        <path
            stroke="none"
            stroke-width="1"
            fill-rule="evenodd"
            d="M13.9524719,20.4767424 C14.2401816,22.1084253 13.1506761,23.6644005 11.5189932,23.9521102 C9.88731026,24.2398199 8.3313351,23.1503144 8.04362538,21.5186315 C7.86042236,20.4796355 7.70776288,19.6138606 7.58564694,18.9213066 C4.9829618,18.4885378 3,16.2258417 3,13.5 C3,10.4624339 5.46243388,8 8.5,8 L16.4173647,8 L20.3888277,5.47150185 C21.78646,4.58167598 23.6408112,4.99333439 24.5306371,6.39096665 C24.8371745,6.872439 25,7.4313668 25,8.00213891 L25,18.9978611 C25,20.6547153 23.6568542,21.9978611 22,21.9978611 C21.4292279,21.9978611 20.8703001,21.8350355 20.3888277,21.5284982 L16.4173647,19 L13.6920013,18.9995405 C13.7660659,19.4195812 13.8528894,19.9119818 13.9524719,20.4767424 Z M10.0132409,21.1713351 C10.1091441,21.7152294 10.6278025,22.0783979 11.1716968,21.9824947 C11.7155911,21.8865915 12.0787596,21.3679331 11.9828564,20.8240388 C11.8579134,20.1154521 11.7506209,19.5069656 11.6609787,18.9985794 L9.63019776,18.9989896 C9.73502842,19.5935138 9.86270947,20.317629 10.0132409,21.1713351 Z M21.4629426,7.15859322 L17,10 L17,17 L21.4629426,19.8414068 C21.6234334,19.9435859 21.8097426,19.9978611 22,19.9978611 C22.5522847,19.9978611 23,19.5501458 23,18.9978611 L23,8.00213891 C23,7.81188154 22.9457248,7.62557228 22.8435457,7.46508149 C22.5469371,6.99920407 21.92882,6.8619846 21.4629426,7.15859322 Z M15,9.99976107 L8.5,10 C6.56700338,10 5,11.5670034 5,13.5 C5,15.4329966 6.56700338,17 8.5,17 L15,16.9997611 L15,9.99976107 Z"
            id="↳-Icon-Color"
            fill="currentColor"
        ></path>
    </svg>
</template>

<script>
export default {
    name: "IVkCamp",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
