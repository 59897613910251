import axios from "axios";
import router from "@/services/router";

export default {
    state: {
        menu: [],
        profile: [],
    },

    mutations: {
        setProfile(state, profile) {
            state.profile = profile;
            localStorage.setItem("profile", JSON.stringify(profile));
        },
        setMenu(state, menu) {
            state.menu = menu;
            localStorage.setItem("menu", JSON.stringify(menu));
        },
    },

    actions: {
        login(ctx, data) {
            console.log("Login");
            let formData = new FormData();
            formData.append("username", data.username);
            formData.append("password", data.password);
            axios.post("https://eridapi.timekraken.ru/site/login", formData).then((res) => {
                console.log(res.data);
                console.log(res.data.menu);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                if (res.data && res.data.token) {
                    localStorage.setItem("AUTH", res.data.token);
                    ctx.commit("setProfile", res.data.me);
                    ctx.commit("setMenu", res.data.menu);
                    router.push("/erid");
                }
            });
        },

        registration(ctx, data) {
            let formData = new FormData();
            formData.append("username", data.username);
            formData.append("email", data.email);
            formData.append("password", data.password);
            axios.post("https://eridapi.timekraken.ru/site/registration", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                if (res.data && res.data.token) {
                    localStorage.setItem("AUTH", res.data.token);
                    ctx.commit("setProfile", res.data.me);
                    ctx.commit("setMenu", res.data.menu);
                    router.push("/ads");
                    ctx.dispatch("setActiveMemu", "/ads");
                }
            });
        },

        loginVk(ctx, data) {
            let formData = new FormData();
            formData.append("vkid", data.vkid);
            formData.append("email", data.email);
            formData.append("token", data.token);
            axios.post("https://eridapi.timekraken.ru/site/login-vk", formData).then((res) => {
                console.log("loginVk", res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                if (res.data && res.data.token) {
                    localStorage.setItem("AUTH", res.data.token);
                    ctx.commit("setProfile", res.data.me);
                    ctx.commit("setMenu", res.data.menu);
                    router.push("/ads");
                    ctx.dispatch("setActiveMemu", "/ads");
                }
            });
        },

        logout(ctx) {
            localStorage.removeItem("AUTH");
            localStorage.removeItem("profile");
            ctx.commit("setProfile", null);
            ctx.commit("setMenu", null);
            router.push({ name: "Login" });
        },

        getProfile(ctx) {
            if (ctx.state.profile.id && ctx.state.menu) {
                return;
            }

            let profile = localStorage.getItem("profile");
            if (profile && profile != "undefined") {
                ctx.commit("setProfile", JSON.parse(profile));
            }

            let menu = localStorage.getItem("menu");
            if (menu) {
                ctx.commit("setMenu", JSON.parse(menu));
            }

            // if (!ctx.state.profile?.id || !ctx.state.menu) {
            //     router.push("/login");
            // }
        },
    },

    getters: {
        menu(state) {
            return state.menu;
        },
        profile(state) {
            return state.profile;
        },
    },
};
