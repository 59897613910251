<template>
    <svg
        :class="'w-' + size + ' h-' + size"
        :width="size * 4"
        :height="size * 4"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M19 9L12 15L10.25 13.5M5 9L7.33333 11" />
    </svg>
</template>

<script>
export default {
    name: "IconAltDown",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
