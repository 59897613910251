<template>
    <div class="w-1/2 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-120px)] rounded overflow-hidden z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-sky-600 text-white">РЕДАКТИРОВАТЬ КОНТРАГЕНТА</h3>

        <div class="flex flex-col p-8 pb-40 text-center h-full overflow-auto">
            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Название</label>
                <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="agent.name" />
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Организационно-правовая форма</label>

                <div class="w-full flex justify-between items-center mb-4 text-sm bg-slate-100 text-slate-400 rounded">
                    <div
                        @click="agent.type = 'physical'"
                        class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                        :class="{ '!bg-sky-600 !text-white': agent.type == 'physical' }"
                    >
                        <span class="flex items-center">Физическое лицо</span>
                    </div>
                    <div
                        @click="agent.type = 'ip'"
                        class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                        :class="{ '!bg-sky-600 !text-white': agent.type == 'ip' }"
                    >
                        <span class="flex items-center">ИП</span>
                    </div>
                    <div
                        @click="agent.type = 'juridical'"
                        class="flex justify-center items-center w-1/3 h-8 text-center rounded cursor-pointer"
                        :class="{ '!bg-sky-600 !text-white': agent.type == 'juridical' }"
                    >
                        <span class="flex items-center">Юридическое лицо</span>
                    </div>
                </div>
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Роли</label>

                <template v-for="(role, key) in roles" :key="role">
                    <div
                        v-if="JSON.parse(agent.roles)?.includes(key)"
                        class="flex items-center gap-4 text-sm text-teal-600 mb-2 cursor-pointer"
                        @click="agentRole(key)"
                    >
                        <IconOk />{{ role }}
                    </div>
                    <div v-else class="flex items-center gap-4 text-sm text-slate-400 mb-2 cursor-pointer" @click="agentRole(key)">
                        <IconClose />{{ role }}
                    </div>
                </template>
            </div>

            <div v-if="JSON.parse(agent.roles)?.includes('ors')" class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Ссылка на рекламную сеть</label>
                <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="agent.url" />
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">ИНН</label>
                <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="agent.inn" />
            </div>

            <div class="flex flex-col mb-4">
                <label class="w-full text-xs text-left text-slate-400 mb-1">Телефон</label>
                <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="agent.phone" />
            </div>

            <hr />
            <p class="text-left text-sm text-slate-400 my-2">Дополнительно</p>

            <div class="flex text-sm mb-4">
                <label class="w-32 text-left">Статус</label>
                <div v-if="agent.status == 1" class="text-slate-400">Создан</div>
                <div v-if="agent.status == 2" class="text-slate-400">Проверен</div>
                <div v-if="agent.status == 3" class="text-slate-400">Отклонен</div>
                <div v-if="agent.status == 7" class="text-slate-400">Отправлен в ОРД</div>
            </div>

            <div class="flex text-sm mb-4">
                <label class="w-32 text-left">Внешний ID</label>
                <div class="text-slate-400">{{ agent.external_id }}</div>
            </div>
        </div>

        <div class="flex justify-end gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0">
            <div class="btn btn-green text-sm py-2 px-4" @click="saveAgent(agent), closePopup(), pickAgent(null)">Сохранить</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "EditAgent",

    data() {
        return {
            moment: moment,
            roles: {
                advertiser: "Рекламодатель",
                agency: "Агентство",
                ors: "Рекламная система",
                publisher: "Издатель",
            },
        };
    },

    computed: {
        ...mapGetters(["s", "agent"]),
    },

    methods: {
        ...mapActions(["saveAgent", "closePopup", "pickAgent"]),

        agentRole(role) {
            let roles = new Set(JSON.parse(this.agent.roles));
            if (roles.has(role)) {
                roles.delete(role);
            } else {
                roles.add(role);
            }
            this.agent.roles = JSON.stringify([...roles]);
        },
    },

    mounted() {
        this.agent.status = 1;
    },
};
</script>
