<template>
    <!-- <div class="h-screen min-h-screen">
        <div class="p-10 mt-4 mx-auto bg-white rounded max-w-xl w-full">
            <div class="p-4 text-center">
                <div class="flex flex-col justify-center text-lg uppercase font-latobold">
                    <div class="text-sky-600 mr-4">Входим через VK</div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="h-screen min-h-screen flex justify-center items-center bg-sky-800">
        <div
            @click="loginWithVk()"
            class="fixed flex flex-col z-10 w-96 rounded-r-full rounded-tl-full bg-white/50 py-5 px-10 text-center text-sky-800 backdrop-blur shadow-2xl hover:bg-white/80 hover:-mt-4 duration-1000 cursor-pointer"
        >
            <span class="text-3xl font-bold p-4">Входим через VK</span>
        </div>

        <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "VkAuth",

    data() {
        return {
            form: "login",
            name: null,
            email: null,
            username: null,
            password: null,
        };
    },

    methods: {
        ...mapActions(["loginVk"]),

        VkAuthUrl() {
            const url = window.location.href;

            if (url.includes("access_token")) {
                // Разбираем URL, чтобы извлечь параметры
                const urlParams = new URLSearchParams(url.split("#")[1]);

                // Получаем access_token и другие параметры из URL
                const vkid = urlParams.get("user_id");
                const email = urlParams.get("email");
                const token = urlParams.get("access_token");
                // console.log("vkid", vkid);
                // console.log("email", email);
                // console.log("token", token);

                this.loginVk({ vkid: vkid, email: email, token: token });
            }
        },
    },

    mounted() {
        this.VkAuthUrl();
    },
};
</script>
