<template>
    <div class="w-full h-28 relative overflow-hidden p-0 m-0">
        <div id="chart" class="w-full h-28 relative"></div>

        <div
            v-if="toolTip?.sum >= 0"
            class="flex flex-col justify-center items-center absolute top-0 z-40 w-20 h-8 text-xs text-center bg-teal-600/80 rounded"
            :style="toolTip.left"
        >
            <span class="text-sm font-bold text-white leading-4">{{ parseInt(toolTip.sum).toLocaleString() }}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { createChart } from "lightweight-charts";
import moment from "moment";

export default {
    name: "LineGraf",

    props: {
        graf: {
            type: Array,
        },
    },

    data() {
        return {
            chart: null,
            toolTip: [],
            moment: moment,
        };
    },

    computed: {
        ...mapGetters([]),
    },

    watch: {
        graf() {
            this.showGraf();
        },
    },

    methods: {
        showGraf() {
            if (!this.graf || this.graf.length < 1) return;

            document.getElementById("chart").innerHTML = "";

            this.chart = createChart(document.getElementById("chart"), {
                rightPriceScale: {
                    visible: true,
                    borderVisible: false,
                },
                timeScale: {
                    borderVisible: false,
                    visible: true,
                },
                layout: {
                    background: { color: "transparent" },
                },
                grid: {
                    horzLines: {
                        color: "#eee",
                    },
                    vertLines: {
                        color: "transparent",
                    },
                },
                autoSize: true,
            });

            this.sumLine = this.chart.addAreaSeries({
                topColor: "rgba(10, 150, 136, 0.56)",
                bottomColor: "rgba(10, 150, 136, 0.04)",
                lineColor: "rgba(10, 150, 136, 1)",
                lineWidth: 2,
                lineType: 2,
                priceLineVisible: false,
                priceFormat: {
                    minMove: 1,
                    precision: 0,
                },
            });
            this.sumLine.setData(this.graf);

            this.chart.applyOptions({ autoSize: true, handleScroll: false, handleScale: false, crosshair: { mode: "Magnet" } });

            this.chart.timeScale().fitContent();

            this.chart.subscribeCrosshairMove((param) => {
                if (!param || !param.point || param.point.x < 0 || param.point.y < 0 || !param.time) {
                    this.toolTip.sum = -1;
                    return;
                }
                let data = param.seriesData.get(this.sumLine);
                if (data && data.value) {
                    this.toolTip.sum = data.value;
                }

                let center = this.chart.timeScale().timeToCoordinate(param.time) - 40; // 40 половина от ширины плашки (80)
                let chartWidth = document.getElementById("chart").offsetWidth;
                if (center < 0) center = 0;
                if (center > chartWidth - 80) center = chartWidth - 80;

                this.toolTip.time = param.time;
                this.toolTip.left = { left: center + "px" };
            });
        },
    },

    mounted() {
        this.showGraf();
        window.addEventListener("resize", this.showGraf());
    },
};
</script>
