<template>
    <div class="w-3/4 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-112px)] rounded overflow-hidden z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-cyan-500 text-white">РЕДАКТИРОВАТЬ ПОЛЬЗОВАТЕЛЯ</h3>

        <div class="grid grid-cols-3 gap-8 p-8 pb-32 text-center h-full overflow-auto">
            <div class="flex flex-col text-left">
                <div class="col-span-4 text-slate-400 mb-2">Профиль</div>

                <label class="w-full text-xs text-left text-slate-400 mb-1">Имя</label>
                <input type="text" class="input mb-4" v-model="user.name" />

                <label class="w-full text-xs text-left text-slate-400 mb-1">Фамилия</label>
                <input type="text" class="input mb-4" v-model="user.secondname" />

                <label class="w-full text-xs text-left text-slate-400 mb-1">Логин</label>
                <input type="text" class="input mb-4" disabled v-model="user.username" />

                <label class="w-full text-xs text-left text-slate-400 mb-1">Статус</label>
                <div class="w-full flex justify-between items-center text-sm bg-slate-100 text-slate-400 rounded">
                    <div
                        @click="user.status = 1"
                        class="flex justify-center items-center w-1/2 h-8 text-center rounded cursor-pointer"
                        :class="{ '!bg-teal-400 !text-white': user.status == 1 }"
                    >
                        <span class="flex items-center">Активен</span>
                    </div>

                    <div
                        @click="user.status = 0"
                        class="flex justify-center items-center w-1/2 h-8 text-center rounded cursor-pointer"
                        :class="{ '!bg-red-400 !text-white': user.status == 0 }"
                    >
                        <span class="flex items-center">Выключен</span>
                    </div>
                </div>
            </div>

            <div class="flex flex-col text-left">
                <div class="col-span-4 text-slate-400 mb-2">Роли</div>

                <template v-for="(role, key) in roles" :key="role">
                    <div
                        v-if="JSON.parse(user.roles).includes(key)"
                        class="flex items-center gap-4 text-sm text-teal-600 mb-2 cursor-pointer"
                        @click="delUserRole(key)"
                    >
                        <IconOk />{{ role }}
                    </div>
                    <div v-else class="flex items-center gap-4 text-sm text-slate-400 mb-2 cursor-pointer" @click="addUserRole(key)">
                        <IconClose />{{ role }}
                    </div>
                </template>

                {{ user.roles }}
            </div>

            <div class="grid grid-cols-4 pb-8 mb-4 gap-4">
                <div class="col-span-4 text-slate-400 text-left">Уведомления</div>
            </div>
        </div>

        <div class="flex justify-between gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0 border-t border-slate-200">
            <div class="btn btn-empty-red text-sm py-2 px-4" @click="setPopup('deleteUser'), pickUser(user)">Удалить</div>
            <div class="btn btn-green text-sm py-2 px-4" @click="saveUser(user), closePopup()">Сохранить</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "EditUser",

    computed: {
        ...mapGetters(["s", "user", "roles"]),
    },

    methods: {
        ...mapActions(["saveUser", "pickUser", "setPopup", "closePopup", "addUserRole", "delUserRole"]),
    },
};
</script>
