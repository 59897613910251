<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <!-- fill="#07F" -->
        <path
            fill="currentColor"
            d="M0 11.41c0-5.379 0-8.074 1.671-9.74C3.343.005 6.031 0 11.41 0h1.181c5.378 0 8.067 0 9.739 1.671C24 3.343 24 6.031 24 11.41v1.181c0 5.378 0 8.067-1.671 9.739C20.657 24 17.969 24 12.59 24h-1.18c-5.378 0-8.067 0-9.739-1.671C0 20.657 0 17.969 0 12.59v-1.18Z"
        ></path>
        <path fill="#00D3E6" d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"></path>
        <path
            fill="#fff"
            fill-rule="evenodd"
            d="M19.444 3.01v1.54h1.551c.197 0 .39.055.554.16a.984.984 0 0 1 .37.435.96.96 0 0 1-.2 1.065l-2.126 2.162a.999.999 0 0 1-.723.303h-2.01l-2.08 2.206c.159.371.24.77.237 1.173a2.91 2.91 0 0 1-.507 1.637c-.33.484-.8.862-1.35 1.085a3.067 3.067 0 0 1-1.739.167 3.029 3.029 0 0 1-1.54-.806 2.928 2.928 0 0 1-.823-1.508 2.89 2.89 0 0 1 .171-1.703 2.96 2.96 0 0 1 1.108-1.322 3.054 3.054 0 0 1 1.671-.496c.277 0 .551.036.817.108l2.39-2.568V5.026a.966.966 0 0 1 .315-.713l2.208-2.05a1.013 1.013 0 0 1 1.083-.183 1 1 0 0 1 .44.36c.107.16.165.346.166.537l.017.033Z"
            clip-rule="evenodd"
        ></path>
    </svg>
</template>

<script>
export default {
    name: "IVkAds",
    props: {
        size: {
            type: Number,
            default: 8,
        },
    },
};
</script>
