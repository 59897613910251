<template>
    <div v-if="!s.request" class="py-16 bg-slate-50">
        <div class="flex justify-center items-center gap-4 mb-8" @click="allParse()">
            <div class="flex justify-center items-center text-slate-500 gap-2 uppercase">
                <span class="rounded-r-full rounded-tl-full bg-sky-800 px-4 text-center text-2xl font-bold text-white"> {{ noErids }} </span>
                БЕЗ ERID
            </div>
        </div>

        <div class="grid grid-cols-3 items-start gap-4 mb-8">
            <div class="h-full">
                <div class="sticky top-16">
                    <div class="bg-white mb-1 rounded cursor-pointer" v-for="client in clients" :key="client">
                        <div class="flex items-center text-sky-800 py-1 px-4 rounded gap-4" @click="pickClient(client)">
                            <span
                                class="rounded-r-full rounded-tl-full bg-slate-200 px-4 text-center text-md font-bold text-white"
                                :class="{ '!bg-sky-800 ': client.noerid > 0 }"
                            >
                                {{ client.noerid }}
                            </span>
                            <div class="flex flex-col text-sm">
                                <span class="text-xs text-slate-300">{{ client.id }}</span>
                                {{ client.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-span-2">
                <div class="bg-white rounded" v-for="item in ads" :key="item.id">
                    <div
                        class="h-12 overflow-hidden grid grid-cols-12 items-center gap-4 px-4 mb-px text-[13px] hover:bg-sky-50/50 hover:!text-sky-700"
                    >
                        <div>
                            <a v-if="item.url" :href="item.url" target="_blank" class="text-sky-600"><IVkAd :size="8" /></a>
                        </div>

                        <div class="flex items-center gap-4 col-span-7">
                            <a
                                class="text-2xl text-sky-600 font-bold text-right cursor-pointer"
                                target="_blank"
                                :href="'https://vk.com/ads?act=office&union_id=' + item.id"
                            >
                                <IVk />
                            </a>
                            <div>
                                <span class="text-xs text-slate-400">{{ item.id }}</span>
                                <div class="flex">{{ item.name?.slice(0, 40) }} <span v-if="item.name?.length > 40">...</span></div>
                            </div>
                        </div>

                        <div class="flex justify-end items-center gap-2">
                            {{ parseInt(item.impressions) }}
                            <span class="text-slate-400"> <IconEye :size="3" /></span>
                        </div>
                        <div class="flex justify-end items-center gap-2">
                            {{ parseInt(item.clicks) }}
                            <span class="text-slate-400"><IconClick :size="3" /></span>
                        </div>
                        <div class="flex justify-end items-center gap-2">
                            {{ parseFloat(item.spent / 100) }}
                            <span class="text-slate-400">₽</span>
                        </div>

                        <div>
                            <div class="flex justify-end items-center gap-2 mr-1" v-if="item.status == 1">
                                <span class="circle bg-teal-500"></span>
                            </div>
                            <div class="flex justify-end items-center gap-2 mr-1" v-if="item.status == 0">
                                <span class="circle bg-pink-500"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else class="flex justify-center items-center h-screen text-sky-400"><IconLoader :size="30" /></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "MyAds",

    data() {
        return {
            moment: moment,

            ad_formats: {
                1: "изображение и текст",
                2: "большое изображение",
                3: "эксклюзивный формат",
                4: "продвижение сообществ",
                5: "приложение в новостной ленте",
                6: "мобильное приложение",
                9: "запись в сообществе",
                11: "адаптивный формат",
                12: "истории",
            },
        };
    },

    computed: {
        ...mapGetters(["s", "select", "profile", "clients", "client", "ads"]),

        grouped() {
            if (!this.ads || this.ads.length < 1) return null;

            const grouped = this.ads.reduce((result, item) => {
                const outerKey = item.client_id;

                if (!result[outerKey]) {
                    result[outerKey] = { id: item.client_id, name: item.client, items: [], count: 0 };
                }

                result[outerKey].items.push(item);
                result[outerKey].count += 1;

                return result;
            }, {});

            return grouped;
        },

        noErids() {
            if (!this.clients || this.clients.length < 1) return 0;
            return this.clients.reduce((result, item) => result + parseInt(item.noerid), 0);
        },
    },

    methods: {
        ...mapActions(["getClients", "setPopup", "closePopup", "clearSettings", "pickClient", "allParse"]),
    },

    mounted() {
        this.getClients();
    },
};
</script>
