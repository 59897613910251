<template>
    <div v-if="!s.request" class="bg-slate-100 p-4 pt-16">
        <div class="bg-white rounded p-4 mb-4">
            <div class="flex justify-between items-center gap-4">
                <div class="flex justify-center items-center text-slate-500 gap-2">
                    КЛИЕНТСКИЕ КАБИНЕТЫ <span class="count">{{ clients?.length }}</span>
                </div>
            </div>
        </div>

        <template v-for="cabinet in cabinets" :key="cabinet">
            <div class="bg-white rounded mt-8">
                <div class="flex items-center h-12 px-4 gap-4 text-lg text-sky-800 border-b border-slate-200">
                    <span class="text-xs">{{ cabinet.id }}</span> {{ cabinet.name }}
                </div>
                <template v-for="client in cabinet.clients" :key="client.id">
                    <div class="flex items-center h-12 px-4 gap-4 text-md text-slate-500 border-b border-slate-200">
                        <span class="text-xs">{{ client.id }}</span>
                        {{ client.name }}
                    </div>
                </template>
            </div>
        </template>
    </div>

    <div v-else class="flex justify-center items-center h-screen text-sky-400"><IconLoader :size="30" /></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "ClientsPage",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "select", "profile", "clients"]),

        cabinets() {
            if (!this.clients || this.clients.length < 1) return null;

            const grouped = this.clients.reduce((result, item) => {
                const CABINET = item.label;

                if (!result[CABINET]) {
                    result[CABINET] = { name: item.label, clients: [] };
                }

                result[CABINET].clients.push(item);
                return result;
            }, {});

            return grouped;
        },
    },

    methods: {
        ...mapActions(["getClients"]),
    },

    mounted() {
        this.getClients();
    },
};
</script>
