<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.98 3C22.2002 3 24 4.79982 24 7.02V20.98C24 23.2002 22.2002 25 19.98 25H8.02C5.79982 25 4 23.2002 4 20.98V7.02C4 4.79982 5.79982 3 8.02 3H19.98ZM19.99 5H8.01C6.89991 5 6 5.89991 6 7.01V20.99C6 22.1001 6.89991 23 8.01 23H19.99C21.1001 23 22 22.1001 22 20.99V7.01C22 5.89991 21.1001 5 19.99 5ZM18.995 11C19.55 11 20 11.45 20 12.005V19.995C20 20.55 19.55 21 18.995 21H9.005C8.44995 21 8 20.55 8 19.995V12.005C8 11.45 8.44995 11 9.005 11H18.995ZM15 7C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H9C8.44772 9 8 8.55228 8 8C8 7.44772 8.44772 7 9 7H15Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: "IVkAd",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
