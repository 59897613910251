<template>
    <div class="w-2/3 min-h-min h-4/5 fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto rounded z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-cyan-500 text-white">СОЗДАТЬ ПРОЕКТ</h3>

        <div class="flex flex-col p-8 pb-32 text-center h-full overflow-auto">
            <div class="flex flex-col items-center mb-4 relative">
                <label class="w-1/4 text-xs text-right text-slate-400 absolute top-1 right-2">Название</label>
                <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.name" />
            </div>

            <div class="flex flex-col items-center mb-4 relative">
                <label class="w-1/4 text-xs text-right text-slate-400 absolute top-1 right-2">Используй {link}</label>
                <textarea class="w-full h-72 py-1 px-3 text-sm bg-slate-100 rounded" v-model="project.text"></textarea>
            </div>
        </div>

        <div class="flex justify-end gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0">
            <div class="btn btn-green text-sm py-2 px-4" @click="createProject(project)">СОЗДАТЬ</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "AddProject",

    data() {
        return {
            moment: moment,
            project: {},
        };
    },

    computed: {
        ...mapGetters(["s"]),
    },

    methods: {
        ...mapActions(["createProject", "closePopup"]),
    },
};
</script>
