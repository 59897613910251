import { createRouter, createWebHistory } from "vue-router";
import authService from "./auth";

import MainPage from "@/Main/Main";
import LoginPage from "@/Main/Login";
import ProfilePage from "@/Main/Profile";
import VkAuth from "@/Main/VkAuth";

import CabinetsPage from "@/Pages/Cabinets";
import ClientsPage from "@/Pages/Clients";
import AdsPage from "@/Pages/Ads";

const routes = [
    {
        path: "/",
        name: "Main",
        component: MainPage,
    },
    {
        path: "/login",
        name: "Login",
        component: LoginPage,
    },
    {
        path: "/profile",
        name: "Profile",
        component: ProfilePage,
    },
    {
        path: "/auth",
        name: "VkAuth",
        component: VkAuth,
    },
    {
        path: "/cabinets",
        name: "Cabinets",
        component: CabinetsPage,
        meta: { title: "Кабинеты" },
    },
    {
        path: "/clients",
        name: "Clients",
        component: ClientsPage,
        meta: { title: "Клиенты" },
    },
    {
        path: "/ads",
        name: "Ads",
        component: AdsPage,
        meta: { title: "Объявления" },
    },
];

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to) => {
    document.title = to.meta.title || "VK";

    if (
        // make sure the user is authenticated
        !authService.isLogged() &&
        // Avoid an infinite redirect
        to.name !== "Login" &&
        to.name !== "VkAuth" &&
        to.name !== "Main"
    ) {
        // redirect the user to the login page
        // this.$router.push('/')
        return { name: "Login" };
    }
    window.scrollTo(0, 0);
});

export default router;
