<template>
    <div class="w-2/3 h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-112px)] overflow-auto rounded z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-red-500 text-white">УДАЛИТЬ КРЕАТИВ</h3>

        <div class="flex flex-col p-10 text-center">
            <p>Вы точно хотите удалить креатив</p>

            <p class="my-4 font-bold text-3xl">"{{ template.name }}"</p>

            <p>Его можно будет восстановить!</p>

            <div class="flex justify-center mt-8">
                <div class="btn btn-red py-2 px-6" @click="delUser(template.id), closePopup()">Удалить</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "DeleteUser",

    computed: {
        ...mapGetters(["s", "template"]),
    },

    methods: {
        ...mapActions(["delUser", "closePopup"]),
    },
};
</script>
