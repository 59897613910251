import API from "../../services/api";

export default {
    state: {
        cabinets: [],
        cabinetsF: [],
        cabinet: {},

        clients: [],
        clientsF: [],
        client: {},

        ads: [],
        ad: {},
    },

    mutations: {
        setCabinets(state, cabinets) {
            state.cabinets = cabinets;
            state.cabinetsF = cabinets;
        },
        setCabinetsF(state, cabinets) {
            state.cabinetsF = cabinets;
        },
        setCabinet(state, cabinet) {
            localStorage.setItem("cabinet", JSON.stringify(cabinet));
            state.cabinet = cabinet;
        },

        setClients(state, clients) {
            state.clients = clients;
            state.clientsF = clients;
        },
        setClientsF(state, clients) {
            state.clientsF = clients;
        },
        setClient(state, client) {
            localStorage.setItem("client", JSON.stringify(client));
            state.client = client;
        },

        setAds(state, ads) {
            state.ads = ads;
        },
        setAd(state, ad) {
            localStorage.setItem("ad", JSON.stringify(ad));
            state.ad = ad;
        },
    },

    actions: {
        getCabinets(ctx) {
            if (!ctx.state.cabinets || ctx.state.cabinets?.length < 1) {
                ctx.dispatch("request", true);
                API.GET("ad/cabinets").then((res) => {
                    console.log("getCabinets", res.data);
                    if (res.data.prohibition) return ctx.dispatch("logout");
                    if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                    ctx.commit("setCabinets", res.data.cabinets);
                    ctx.dispatch("filterCabinets");
                    ctx.dispatch("request", false);
                });
            }
        },

        getClients(ctx) {
            if (!ctx.state.stats || ctx.state.stats?.length < 1) {
                ctx.dispatch("request", true);
                API.GET("ad/clients").then((res) => {
                    if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                    ctx.commit("setClients", res.data.clients);
                    // ctx.dispatch("filterClients");
                    ctx.dispatch("request", false);
                });
            }
        },

        // getAds(ctx) {
        //     console.log("getAds");
        //     ctx.dispatch("request", true);
        //     API.GET("ad/ads").then((res) => {
        //         console.log("getAds", res);
        //         if (res.data.alert) ctx.commit("setAlert", res.data.alert);
        //         ctx.commit("setClients", res.data.clients);
        //         ctx.commit("setAds", res.data.ads);
        //         ctx.dispatch("request", false);
        //     });
        // },

        pickClient(ctx, client) {
            console.log("pickClient", client.name);
            ctx.commit("setClient", client);
            // ctx.dispatch("request", true);

            API.GET("ad/ads", { client_id: client.id }).then((res) => {
                console.log("getAds", res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setAds", res.data.ads);
                // ctx.dispatch("request", false);
            });
        },

        allParse(ctx) {
            API.GET("ad/parsing").then((res) => {
                console.log("allParse", res);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
            });
        },
    },

    getters: {
        cabinets(state) {
            return state.cabinetsF;
        },
        cabinet(state) {
            return state.cabinet;
        },

        clients(state) {
            return state.clientsF;
        },
        client(state) {
            return state.client;
        },
        ads(state) {
            return state.ads;
        },
        ad(state) {
            return state.ad;
        },
    },
};
