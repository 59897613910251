import API from "../../services/api";

export default {
    state: {},

    mutations: {},

    actions: {
        getTargetologAds(ctx) {
            if (!ctx.state.stats || ctx.state.stats?.length < 1) {
                ctx.dispatch("request", true);
                API.GET("ad/ads").then((res) => {
                    if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                    if (res.data.ads) {
                        ctx.commit("setAds", res.data.ads);
                        // ctx.dispatch("filterAds");
                    }
                    ctx.dispatch("request", false);
                });
            }
        },

        getTargetologErids(ctx) {
            if (!ctx.state.stats || ctx.state.stats?.length < 1) {
                ctx.dispatch("request", true);
                API.GET("ad/erids").then((res) => {
                    console.log("erids", res.data);
                    if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                    ctx.commit("setAds", res.data.ads);
                    ctx.dispatch("request", false);
                });
            }
        },

        getTargetologReport(ctx) {
            if (!ctx.state.stats || ctx.state.stats?.length < 1) {
                ctx.dispatch("request", true);
                API.GET("ad/report").then((res) => {
                    console.log("report", res.data);
                    if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                    ctx.commit("setStats", res.data.stats);

                    ctx.dispatch("request", false);
                });
            }
        },

        unLinkMyClient(ctx, id) {
            ctx.dispatch("request", true);

            let formData = new FormData();
            formData.append("id", id);

            API.POST("ad/un-link-client", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setClients", res.data.clients);
                ctx.dispatch("request", false);
            });
        },

        linkMyClient(ctx, data) {
            ctx.dispatch("request", true);

            let formData = new FormData();
            formData.append("project", data.project);
            formData.append("offer", data.offer);
            formData.append("client_id", data.client_id);

            API.POST("ad/link-client", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setClients", res.data.clients);
                ctx.dispatch("request", false);
            });
        },
    },

    getters: {},
};
