<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 170.08 170.08" xmlns="http://www.w3.org/2000/svg">
        <path
            fill="#fff"
            d="M28.11,30.71a184,184,0,0,1,30.41-5.55A355.71,355.71,0,0,1,96.6,23.25q10.47,0,21.05.64t19.95,1.69c3.32.38,6.43.78,9.36,1.2A85,85,0,0,0,16.29,35,81.58,81.58,0,0,1,28.11,30.71Z"
        />
        <path
            fill="#fff"
            d="M154.15,42q-5.73-1.18-14-2.67T120.57,36.6a237.08,237.08,0,0,0-25.43-1.23A185.06,185.06,0,0,0,72.27,36.6a90.51,90.51,0,0,0-16.06,3.27q-6.33,2-9.49,4.83t-3.16,5.93q0,4.57,6.08,7.88a70.47,70.47,0,0,0,16.18,5.85q10.09,2.55,23,4.66T115.1,73.3q13.37,2.16,26.27,4.75a175.12,175.12,0,0,1,23,6.1c2,.7,3.9,1.45,5.68,2.23,0-.45,0-.89,0-1.34a84.69,84.69,0,0,0-11.22-42.2C157.51,42.64,156,42.36,154.15,42Z"
        />
        <path
            fill="#fff"
            d="M154.25,134.42s.07-.08.07-.11a9.43,9.43,0,0,1,.79-1.13c.12-.16.23-.27.27-.34,1.33-2,2.57-4,3.74-6-1,.31-2,.62-3,.92A200.92,200.92,0,0,1,122,134a394.29,394.29,0,0,1-42.22,2.12q-15.81,0-29.32-.93T26.4,132.93Q19.17,132,13.53,131A85,85,0,0,0,128,158.43h0l42.07,11.65C146,153.27,151.42,139.09,154.25,134.42Z"
        />
        <path
            fill="#fff"
            d="M10.59,115.89q6.21,1.44,15.94,3.14T50,122.21a301.82,301.82,0,0,0,31.75,1.48,226.35,226.35,0,0,0,24.94-1.27,121.66,121.66,0,0,0,19.47-3.6q8.14-2.34,12.53-5.73c2.92-2.25,4.38-4.83,4.38-7.71q0-4.66-6.09-8a69.91,69.91,0,0,0-16.06-5.8Q111,89.1,98.18,87L72,82.79Q58.64,80.63,46,78a173.19,173.19,0,0,1-22.63-6.19A59.07,59.07,0,0,1,7.18,63.47,18.61,18.61,0,0,1,3.74,60a85.29,85.29,0,0,0,1.65,54.82Q7.69,115.22,10.59,115.89Z"
        />
    </svg>
</template>

<script>
export default {
    name: "ISenler",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
