import { createApp } from "vue";
import App from "@/App.vue";
import Toaster from "@meforma/vue-toaster";
import "@/assets/tailwind.css";
import components from "@/components";

import store from "@/store/index";
import router from "@/services/router";

const app = createApp(App);

components.forEach((component) => {
    app.component(component.name, component);
});

app.use(store);
app.use(Toaster, { position: "bottom-right" });
app.use(router);

app.mount("#app");

/* <script src="https://vk.com/js/api/openapi.js?169" type="text/javascript"></script>; */
