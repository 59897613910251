<template>
    <div class="w-5/6 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-112px)] rounded overflow-hidden z-50 bg-white shadow-lg">
        <div class="text-center text-2xl py-4 bg-sky-500 text-white">
            <div class="text-xs text-slate-100" v-if="project.client_type == 'person'">Физическое лицо</div>
            <div class="text-xs text-slate-100" v-if="project.client_type == 'individual'">Индивидуальный предприниматель</div>
            <div class="text-xs text-slate-100" v-if="project.client_type == 'legal'">Юридическое лицо</div>
            {{ project.name }}
            <div class="w-full text-center text-xs text-slate-100">{{ moment(project.date).format("DD.MM.YYYY HH:mm:ss") }}</div>
        </div>

        <div class="flex flex-col p-8 pb-32 text-center text-sm h-full overflow-auto">
            <div class="grid grid-cols-3 border-b border-slate-200 mb-4">
                <div class="flex flex-col items-start text-left mb-4 col-span-2">
                    <label class="w-full text-xs text-slate-400">Полное юридическое наименование</label>
                    {{ project.client_name }}
                </div>

                <div class="flex flex-col items-start text-left mb-4">
                    <label class="w-full text-xs text-slate-400">Сокращенное наименование</label>
                    {{ project.client_short }}
                </div>

                <div class="flex flex-col items-start text-left mb-4 col-span-2">
                    <label class="w-full text-xs text-slate-400">Адрес</label>
                    {{ project.address }}
                </div>
                <div class="flex flex-col items-start text-left mb-4">
                    <label class="w-full text-xs text-slate-400">Email</label>
                    {{ project.email }}
                </div>

                <div class="flex flex-col items-start text-left mb-4">
                    <label class="w-full text-xs text-slate-400">ИНН</label>
                    {{ project.inn }}
                </div>

                <div class="flex flex-col items-start text-left mb-4">
                    <label class="w-full text-xs text-slate-400">ОГРН/ОГРНИП</label>
                    {{ project.ogrn }}
                </div>

                <div class="flex flex-col items-start text-left mb-4">
                    <label class="w-full text-xs text-slate-400">Телефон</label>
                    {{ project.phone }}
                </div>
            </div>

            <div class="grid grid-cols-4 border-b border-slate-200 pb-4 mb-4 gap-4">
                <div class="col-span-4 text-cyan-600 text-left">Контактное лицо</div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Контактное лицо</label>
                    {{ project.contact_name }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Контактный телефон</label>
                    {{ project.contact_phone }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Контактный email</label>
                    {{ project.contact_email }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Контактный telegram</label>
                    {{ project.contact_tl }}
                </div>
            </div>

            <div
                v-if="project.subagent_type || project.subagent_name || project.subagent_inn || project.subagent_phone"
                class="grid grid-cols-4 border-b border-slate-200 pb-4 mb-4 gap-4"
            >
                <div class="col-span-4 text-cyan-600 text-left">Субагент</div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Тип субагента</label>
                    <div v-if="project.subagent_type == 'person'">Физическое лицо</div>
                    <div v-if="project.subagent_type == 'individual'">Индивидуальный предприниматель</div>
                    <div v-if="project.subagent_type == 'legal'">Юридическое лицо</div>
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Название субагента</label>
                    {{ project.subagent_name }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Инн субагента</label>
                    {{ project.subagent_inn }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Телефон субагента</label>
                    {{ project.subagent_phone }}
                </div>
            </div>

            <div v-else class="text-cyan-600 text-left border-b border-slate-200 pb-4 mb-4">Субагента нет</div>

            <div class="grid grid-cols-4 border-b border-slate-200 pb-4 mb-4 gap-4">
                <div class="col-span-4 text-cyan-600 text-left">Контракт</div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Номер контракта</label>
                    {{ project.contract_number }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">Дата контракта</label>
                    {{ project.contract_date }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">ОКВЭД</label>
                    {{ project.okved }}
                </div>

                <div class="flex flex-col items-start text-left">
                    <label class="w-full text-xs text-slate-400">НДС</label>
                    <div v-if="project.with_vat == 2">Без НДС</div>
                    <div v-if="project.with_vat == 3">С НДС</div>
                </div>

                <div class="flex flex-col items-start text-left col-span-2">
                    <label class="w-full text-xs text-slate-400">Тип контракта</label>
                    {{ project.contract_type }}
                </div>

                <div class="flex flex-col items-start text-left col-span-2">
                    <label class="w-full text-xs text-slate-400">Предмет договора</label>
                    {{ project.contract_object }}
                </div>
            </div>
        </div>

        <div v-if="['Or', 'Bs', 'Lr'].includes(profile.role)" class="flex justify-center gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0">
            <div class="btn btn-blue text-sm py-2 px-4" @click="editProject(project)">Редактировать</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "ViewProject",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "project"]),
    },

    methods: {
        ...mapActions(["saveProject", "editProject"]),
    },
};
</script>
